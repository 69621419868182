<template>
  <div class="content-wrapper">
      <bo-page-title>
          <template slot="additionalCta">
              <b-button @click="apiGet()" class="btn bg-blue-400 btn-labeled btn-labeled-left ml-1">
                  <b><i class="icon-reload-alt"></i></b> Muat Ulang
              </b-button>
          </template>
      </bo-page-title>
      <!--icon-reload-alt-->
      <div class="content pt-0">
          <div class="form-row mb-2">
            <div class="col-md-6 mb-2">
              <div class="box_call">
                  <div class="row align-items-center">
                  <div class="col-md-auto">
                      <div class="wrap_text">
                        <p>Tujuan</p>
                        <h3 v-if="Object.keys(unit||{}).length">{{unit.aru_unit_tujuan||"-"}}</h3>
                        <h3 v-else> - </h3>
                      </div>
                  </div>
                  </div>
              </div>
            </div>
            <div class="w-100"></div>
            <div class="col-md-6">
                <div class="form-row">                    
                    <div class="col-md-12" v-if="resume.isTutup">
                        <div class="box_call bg_call3" data-popup="tooltip"
                            title="" data-toggle="modal">
                            <div class="row align-items-center">
                            <div class="col-md-auto">
                                <div class="wrap_text">
                                <p class="text_light">Loket Admisi Tutup</p>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="w-100"></div>
            <div class="col-md-6">
              <div @click="ambilAntrean('latestAntreanG')" v-if="Object.keys(unit||{}).length && latestAntreanG && !resume.isTutup" class="box_call bg_call1" style="cursor:pointer; background-color:#820c4c;">
                <div class="row">
                  <div class="col-md-auto">
                    <div class="ic_left">
                      <i class="icon-megaphone"></i>
                    </div>
                  </div>
                  <div class="col-md">
                    <div class="wrap_text">
                      <p class="text_light" style="font-size:30px;">G </p>
                    </div>
                    <span class="text-white">Antrean Informasi</span>
                  </div>
                </div>
              </div>
              <div v-else class="box_call bg_nonaktif">
                <div class="row">
                  <div class="col-md">
                    <div class="wrap_text">
                      <p class="text_light" style="font-size:30px;">G </p>
                    </div>
                    <span class="text-white">Tidak Terdapat Antrean</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div @click="ambilAntrean('latestAntreanH')" v-if="Object.keys(unit||{}).length && latestAntreanH && !resume.isTutup" class="box_call bg_call1" style="cursor:pointer; background-color:#1a92f2;">
                <div class="row">
                  <div class="col-md-auto">
                    <div class="ic_left">
                      <i class="icon-megaphone"></i>
                    </div>
                  </div>
                  <div class="col-md">
                    <div class="wrap_text">
                      <p class="text_light" style="font-size:30px;">H </p>
                    </div>
                    <span class="text-white">Antrean Booking</span>
                  </div>
                </div>
              </div>
              <div v-else class="box_call bg_nonaktif">
                <div class="row">
                  <div class="col-md">
                    <div class="wrap_text">
                      <p class="text_light" style="font-size:30px;">H </p>
                    </div>
                    <span class="text-white">Tidak Terdapat Antrean</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-12">
              <b-table-simple responsive bordered>
                <b-thead>
                  <b-tr>
                    <b-th colspan="6">Detail Resume</b-th>
                  </b-tr>
                  <b-tr>
                    <b-th>Kategori</b-th>
                    <b-th>Belum Dipanggil</b-th>
                    <b-th>Terlewat</b-th>
                    <b-th>VOID</b-th>
                    <b-th>Sudah Dipanggil</b-th>
                    <b-th>Total</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="(value, index) in resume.data" :key="index">
                    <b-td style="background-color: #eee"><strong>{{value.kode}}</strong></b-td>
                    <b-td class="table-info">{{value.queue}}</b-td>
                    <b-td class="table-warning">{{value.miss}}</b-td>
                    <b-td class="table-danger">{{value.void}}</b-td>
                    <b-td class="table-success">{{value.done}}</b-td>
                    <b-td style="background-color: #fff"><strong>{{value.total}}</strong></b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
          </div>
          <div class="row">
              <div class="col-md-12">
              <div class="card">
                  <div class="card-header">
                  <h5 class="card-title font-weight-semibold">Statistik </h5>
                  </div>
                  <div class="card-body">
                  <div class="chart-container">
                      <BarChart :chartData="dataAntreanStatistik"
                          :chartOptions="chartOptions"
                      />
                  </div>
                  </div>
              </div>
              </div>
          </div>
      </div>
      
      <b-modal v-model="openAntrianAdmisi" :title="'Antrian Admisi'" size="sm" hide-footer no-close-on-backdrop hide-header-close>    
        <validation-observer ref="VFormAntreanAdmisi">
          <div class="text-center" v-if="openAntrianAdmisi">
              <div class="modal-shout-icon mb-3 d-inline-block alpha-info text-info rounded-circle">
                  <i class="icon-megaphone"></i>
              </div>
              <h6 class="mb-0">Memanggil Antrean</h6>
              <h3 class="font-weight-semibold mb-0" style="font-size:36px;"> No. {{latestAntrean.ar_no_antrian||"-"}}</h3>

              <div class="mt-1">
                <a href="javascript:;" v-if="!btnPanggil" @click="panggilPasien(latestAntrean)" class="btn btn-warning btn-labeled btn-labeled-left">
                  <b><i class="icon-bell2"></i></b> Panggil Ulang
                </a>

                <a href="javascript:;" v-else class="btn btn-secondary btn-labeled btn-labeled-left">
                  <b><i class="icon-bell2"></i></b> Memanggil ...
                </a>
              </div>

              <div class="form-group mt-3">
                <b-form-radio-group v-model="latestAntrean.ar_status" name="status" :options="mrStatus"></b-form-radio-group>
                <VValidate name="Status" v-model="latestAntrean.ar_status" message="Status wajib diisi" rules="required|oneOf:DONE,VOID,MISS" />
              </div>
              
              <div v-if="latestAntrean.ar_status == 'VOID'" class="form-group text-left mt-3" id="patientNotPresenceInfo">
                  <label for="patientNotPresent">Catatan Ketidakhadiran Pasien<strong class="text-danger">*</strong></label>
                  <textarea v-model="latestAntrean.ar_notes"
                  name="patientNotPresent" id="patientNotPresent" rows="3" class="form-control"
                  placeholder="cth. pasien tidak hadir setelah dipanggil 3x"></textarea>
                  <VValidate :name="'Catatan'" v-model="latestAntrean.ar_notes" :rules="{required: 1, min: 2, max: 254}" />
              </div>

              <div class="mt-1">
                  <a href="javascript:;" @click="hadirPasien(latestAntrean)" class="btn btn-success btn-labeled btn-labeled-left mr-1">
                  <b><i class="icon-checkmark"></i></b>Konfirmasi
                  </a>
              </div>
          </div>
        </validation-observer>
      </b-modal>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
const _ = global._
import Gen from '@/libs/Gen.js'
import BarChart from '@/components/chart/BarChart'


export default {
  extends: GlobalVue,  
  data() {
      return {
          unit : {},
          latestAntreanG : {},
          latestAntreanH : {},
          latestAntrean: {},
          mrStatus: [
            {text: 'Hadir', value: 'DONE'},
            {text: 'Belum Hadir', value: 'MISS'},
            {text: 'Tidak Hadir', value: 'VOID'},
          ],
          resume : {
              totalAntrean : 0,
              totalVoid : 0,
              totalDone : 0
          },
          openAntrianAdmisi: false,
          dataAntreanStatistik: {},
          chartOptions: {
              responsive: true
          },
          btnPanggil: false,
          isShow: false
      }
  },
  components:{
      BarChart
  },
  methods: {
    ambilAntrean(latest){
        const latestAntrean = _.clone(this[latest])
        this.loadingOverlay = true
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:{type:'take-antrian', id: latestAntrean.ar_id}}, 
            "POST"
        ).then( res =>{
            this.$set(this, 'latestAntrean', res.data.data)
            this.$socket.emit('refresh_data', {to : 'Informasi'})
            this.$socket.emit('refresh_data_all_informasi',{})
            this.loadingOverlay = false
            this.openAntrianAdmisi = true
            this.isShow = false

            this.panggilPasien(latestAntrean, latest) // params 2: key dari object

        })
    },
    panggilPasien(antrean, keyLatest){
        this.btnPanggil = true
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:{type:'panggil-pasien', kode: antrean.ar_kode_antrian, id: antrean.ar_id, ar_called :  (antrean.ar_called + 1)}}, 
            "POST"
        ).then(()=>{
            antrean.to = 'Informasi'
            this.$socket.emit('panggil_pasien', antrean)
            this[keyLatest].ar_called = this[keyLatest].ar_called + 1    
            setTimeout(()=>{
                this.btnPanggil = false
            },5000)     
            this.isShow = true
        }).catch(()=>{
            setTimeout(()=>{
                this.btnPanggil = false
            },5000)     
        })
    },
    hadirPasien(antrean){
        this.$refs.VFormAntreanAdmisi.validate().then(success => {
          if(!success) return

          Gen.apiRest(
              "/do/"+this.modulePage,
              {data:{type:'update-status', id: antrean.ar_id, ar_status: this.latestAntrean.ar_status, ar_kode_antrian: antrean.ar_kode_antrian, status_prev: antrean.ar_status}}, 
              "POST"
          ).then(res =>{
              console.log(res)
              const resp = res.data
              // this.apiGet()
              this.$socket.emit('refresh_data', {to : 'Informasi'})
              this.$socket.emit('refresh_data_all_informasi',{})
              return this.$swal({
                  icon: 'success',
                  title: resp.message
              }).then(()=>{
                if(resp.data.ar_status == 'DONE'){
                    this.$router.push({name: 'RoReservasi'})
                }else{
                  this.apiGet()
                  this.openAntrianAdmisi = false
                }
              })
          }).catch(() => {
            this.apiGet()
          })
        })
      }
  },
  mounted(){
      this.sockets.subscribe('disp_refresh_unit', function(){
        if(!this.isPlay){
          this.apiGet()
        }
      })

      this.sockets.subscribe('disp_refresh_data_all_informasi', function(data) {
        console.log(data)
          if(!this.isPlay){
              this.apiGet()
          }
      })
      this.apiGet()
  },
  watch:{
      $route(){
          this.apiGet()
      },
      '$route.params.pageSlug':function(){
          this.$set(this,'filter',{})
      }
  },
  destroyed(){
    this.sockets.unsubscribe('disp_refresh_data_all_informasi')
    this.sockets.unsubscribe('disp_refresh_unit')
  }
}
</script> 